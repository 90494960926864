<template>
  <div>
    <SectionTitle title="Glimpse"></SectionTitle>

    <div class="relative my-16">
      <div id="chartdiv" ref="map"></div>

      <div class="w-72 absolute" style="top: 20%; right: 10%" v-if="!openBarsList">
        <div
            class="mb-1 py-2 px-4 w-full flex items-center rounded-t-lg uppercase bg-gradient-to-r from-primary via-blue-800 to-transparent font-bold tracking-wider text-lg">
          Top Jackpots
        </div>

        <a href="" class="w-full flex items-center justify-between mb-1 bg-gray-600 px-4 py-2 hover:bg-secondary group"
           v-for="(bar, index) in $root.$data.barsPerRegion['Lazio']" :key="index">
          <span>{{ bar.name }} (Lazio)</span>
          <span class="text-secondary font-bold group-hover:text-white">{{ bar.totalPrize | money }}</span>
        </a>

        <button class="w-full flex items-center justify-center mb-1 bg-gray-900 px-4 py-2 text-white rounded-b-lg">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2} d="M19 9l-7 7-7-7"/>
          </svg>
        </button>
      </div>

      <div
          class="absolute w-1/3 h-full overflow-y-auto top-0 left-0 bg-black bg-opacity-20 px-10 py-8 flex flex-col gap-2"
          v-if="openBarsList">
        <div class="w-full flex items-center justify-between">
          <h1 class="font-bold text-4xl text-primary uppercase">{{ selectedRegion }}</h1>
          <button class="text-4xl " @click="openBarsList = false">&times;</button>
        </div>
        <p v-if="bars.length === 0" class="w-full text-white text-lg font-semibold py-20">nessun bar in questa regione</p>
        <a @click="openBarPopup(bar, selectedRegion)"
           class="w-full flex items-center gap-6 bg-black bg-opacity-80 text-white py-4 px-8 rounded-lg cursor-pointer hover:bg-secondary hover:bg-opacity-70 group"
           v-for="(bar, index) in bars" :key="index"
           v-else>
          <img class="w-40 h-40 rounded-full" :src="bar.image" :alt="bar.name"/>

          <div class="flex flex-col items-center justify-between flex-1 gap-y-5">
            <h2 class="w-full text-2xl uppercase font-bold text-center text-secondary group-hover:text-white">{{ bar.name }}</h2>
            <div class="w-full flex flex-col items-center gap-1">
              <p class="text-3xl font-bold">{{ bar.totalPrize | money }}</p>
              <p class="uppercase text-base font-semibold">Jackpot da Bar</p>
            </div>
          </div>
        </a>
      </div>
    </div>

    <ClientBarPopup v-if="openBar && barIndex >= 0 && selectedRegion" @closeBarPopup="openBar=null" :bar="openBar" :bar-index="barIndex" :region="selectedRegion"></ClientBarPopup>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import ClientBarPopup from "@/components/ClientBarPopup";

import * as am4core from "@amcharts/amcharts4/core"
import * as am4maps from "@amcharts/amcharts4/maps"
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets"
import am4gdata_italyLow from "@amcharts/amcharts4-geodata/italyLow"

export default {
  name: 'Glimpse',

  components: {
    SectionTitle,
    ClientBarPopup
  },

  data() {
    return {
      map: null,
      openBarsList: false,
      selectedRegion: null,
      openBar: null,
      barIndex: null,
      selectedPolygon: null
    }
  },

  computed: {
    bars() {
      if (this.openBarsList) {
        return this.$root.$data.barsPerRegion[this.selectedRegion] ?? [];
      }

      return [];
    }
  },

  watch: {
    openBarsList(value) {
      if (!value) {
        this.resetMapPosition();
      }
    },
  },

  mounted() {
    this.initializeMap();
    this.setupMapHomeButton();
    this.setupMapRegions();
    this.setupMapBarPins();
  },

  methods: {
    openRegion(region) {
      this.selectedRegion = region;
      this.openBarsList = true;
    },
    openBarPopup(bar, region) {
      this.openBar = bar
      this.selectedRegion = region
      this.barIndex =parseInt(Object.keys(this.$root.$data.barsPerRegion[region]).find(key => this.$root.$data.barsPerRegion[region][key].name === this.openBar.name));
    },
    initializeMap() {
      this.map = am4core.create("chartdiv", am4maps.MapChart)
      this.map.geodata = am4gdata_italyLow
      this.map.projection = new am4maps.projections.Miller()
      this.map.seriesContainer.draggable = true
    },
    setupMapHomeButton() {
      let button = this.map.chartContainer.createChild(am4core.Button);
      button.padding(5, 5, 5, 5);
      button.align = "right";
      button.marginRight = 425;
      button.events.on("hit", (ev) => { this.resetMapPosition() });
      button.icon = new am4core.Sprite();
      button.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
    },
    setupMapRegions() {
      let polygonSeries = this.map.series.push(new am4maps.MapPolygonSeries())
      polygonSeries.useGeodata = true
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.fill = am4core.color("#454545");
      polygonTemplate.strokeWidth = 0.5;

      let hoverState = polygonTemplate.states.create("hover");
      hoverState.properties.fill = am4core.color("#00D3F8");
      hoverState.properties.fillOpacity = 1;

      let activeState = polygonTemplate.states.create("active");
      activeState.properties.fill = am4core.color("#00D3F8");
      activeState.properties.fillOpacity = 0.3;
      activeState.properties.stroke = am4core.color("#00D3F8");
      activeState.properties.strokeWidth = 3;

      polygonTemplate.events.on("hit", (ev) => {
        ev.target.series.chart.zoomToMapObject(ev.target)

        let polygon = ev.target;
        if(this.selectedPolygon && this.selectedPolygon !== polygon) {
          this.selectedPolygon.isActive = false;
          this.selectedPolygon.fillOpacity = 1;
        }
        polygon.isActive = true;
        this.selectedPolygon = polygon;

        this.openRegion(ev.target.dataItem.dataContext.name);
      });
    },
    setupMapBarPins() {
      let imageSeries = this.map.series.push(new am4maps.MapImageSeries());
      let imageTemplate = imageSeries.mapImages.template;
      imageTemplate.propertyFields.longitude = "longitude";
      imageTemplate.propertyFields.latitude = "latitude";
      imageSeries.mapImages.template.tooltipHTML = `<div style="width: fit-content; height: 250px; display: flex; flex-direction: column; align-items: center">
    <img style="width: 200px;" src="{image}" alt=""/>
    <h3 style="width: 100%; text-align: center; font-weight: 600; font-size: 1rem; margin: 1rem 0;">{name} - JP {jackpot}</h3>
    <p style="width: 100%; text-align: center; font-size: 0.825rem;">{address}</p>
</div>`
      imageTemplate.nonScaling = true;
      imageTemplate.events.on('hit', event => {
        const data = event.target.dataItem.dataContext;
        this.openBarPopup(data.bar, data.region)
      });

      let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle.radius = 5;
      circle.propertyFields.fill = "color";

      let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle2.radius = 4;
      circle2.propertyFields.fill = "color";

      circle2.events.on("inited", event => {
        this.animateBullet(event.target);
      })

      let colorSet = new am4core.ColorSet();
      imageSeries.data = [];
      Object.values(this.$root.$data.barsPerRegion).forEach(region => {
        Object.values(region).forEach(bar => {
          imageSeries.data.push({
            "latitude": bar.coordinates.lat,
            "longitude": bar.coordinates.lng,
            "name": bar.name,
            "jackpot": this.$options.filters.money(bar.jackpot),
            "image": bar.image,
            "address": bar.address,
            "color": colorSet.getIndex(10),
            "region": Object.keys(this.$root.$data.barsPerRegion).find(key => this.$root.$data.barsPerRegion[key] === region),
            "bar": bar
          })
        })
      })
    },
    animateBullet(circle) {
      let animation = circle.animate([{property: "scale", from: 1, to: 5}, {
        property: "opacity",
        from: 1,
        to: 0
      }], 1000, am4core.ease.circleOut);
      animation.events.on("animationended", event => {
        this.animateBullet(event.target.object);
      })
    },
    resetMapPosition() {
      this.openBarsList = false;
      this.selectedRegion = null;
      this.selectedPolygon = null;
      this.map.series.getIndex(0).children.values.map(polygon => {
        polygon.isActive = false
        polygon.fillOpacity = 1
      })
      this.map.goHome();
    }
  },

  beforeDestroy() {
    if (this.map) {
      this.map.dispose()
    }
  }
}
</script>

<style scoped>

#chartdiv {
  width: 100%;
  height: 600px;
}
</style>
