<template>
  <div class="bg-gray-800">
    <h2 class="w-full uppercase py-2 px-6 text-black font-semibold text-md tracking-wider bg-gradient-to-r from-secondary via-red-900 to-transparent">
      {{ title }}</h2>

    <slot name="filters"></slot>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: String
  }
}
</script>
