<template>
  <div class="absolute left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70">
    <button class="absolute right-0 top-0 z-30" @click="close">
      <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>
    </button>

    <div class="relative" :style="'width: ' + gameDimensions.width + 'px; height: ' + gameDimensions.height + 'px'">
      <custom-image :original="game.image" :class-list="classList"></custom-image>
      <custom-image :original="game.menu[activeMenu]" class-list="absolute w-full h-full top-0 left-0"></custom-image>
      <custom-image :original="game.games[activeMenu]" :class-list="gameClassList" v-if="game.slug !== 'vlt'"></custom-image>
      <iframe v-if="gameUrl" :src="gameUrl" frameborder="0" class="absolute w-full h-full top-0 left-0 z-40" allowfullscreen></iframe>

      <button @click="previousMenu" class="h-full w-10 absolute top-0 left-0 cursor-pointer z-30"></button>
      <button @click="showGame = !showGame" class="h-96 w-64 absolute cursor-pointer z-30"
              style="top: 50%; left: 50%; transform: translate(-50%,-50%);"
              v-if="cabinetType === 'awp'"></button>
      <button @click="getVltoUrl" class="h-96 w-64 absolute cursor-pointer z-30"
              style="top: 50%; left: 50%; transform: translate(-50%,-50%);"
              v-else></button>
      <button @click="nextMenu" class="h-full w-10 absolute top-0 right-0 cursor-pointer z-30"></button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PlayGame",

  props: {
    game: {
      required: true,
      type: Object
    },
    cabinetType: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      hideSplash: false,
      showGame: false,
      activeMenu: 0,
      gameUrl: null
    }
  },

  computed: {
    classList() {
      let opacity = this.hideSplash ? 'opacity-0' : 'opacity-100'
      return 'absolute w-full h-full top-0 left-0 transition duration-500 z-20 ' + opacity;
    },
    gameClassList() {
      let opacity = !this.showGame ? 'opacity-0' : 'opacity-100'
      return 'absolute w-full h-full top-0 left-0 transition duration-500 z-20 ' + opacity;
    },
    gameDimensions() {
      if (this.game.name === 'vlt') {
        return {
          width: 1200,
          height: 675
        }
      }

      return {
        width: 800,
        height: 600
      };
    }
  },

  mounted() {
    setTimeout(() => {
      this.hideSplash = true
    }, 1500)
  },

  methods: {
    getVltoUrl() {
      const baseUrl = "https://play.dev.gai.luduscristaltec.com/api/gameUrl";
      const accessToken = '49dd7905d52949a8a3a0789f95f0ae7fdf7a7d0bb54faa3ef30f4fb3198966f2d25e75acf9173a7459821ba7c81b6ad164f5'
      const body = {
        game: this.activeMenu === 0 ? 'arizona_vlt' : 'genius_vlt',
        mode: 'fun',
        inactivity_time: 100,
        session_amount: 1000,
        quality: 1,
        redirectUrl: "/"
      }

      axios.post(baseUrl,
          body,
          {
            headers: {
              'x-access-token': accessToken
            }
          })
          .then((response) => {
            if (response.status === 200) {
              this.gameUrl = response.data.url
            }
          })
          .catch(error => {
            this.error = error.response.data
          })
    },
    close() {
      this.$emit('close')
    },
    nextMenu() {
      if (this.activeMenu === this.game.menu.length - 1) {
        this.activeMenu = 0;
      } else {
        this.activeMenu++;
      }
    },
    previousMenu() {
      if (this.activeMenu === 0) {
        this.activeMenu = this.game.menu.length - 1;
      } else {
        this.activeMenu--;
      }
    }
  }
}
</script>

<style scoped>

</style>
