<template>
  <transition name="fade">
    <div class="fixed top-0 left-0 h-screen w-screen p-10 z-20 bg-black bg-opacity-70 flex items-center justify-center">
      <transition name="curtain">
        <div class="bg-gray-600 rounded-md h-full w-3/4 m-0 bg-center bg-cover bg-no-repeat relative"
             style="height: calc(100vw * 0.75 * 0.5625);"
             :style="'background-image: url('  + barBg +  ');'"
             ref="barPopup" v-if="showBar">
          <button class="absolute right-0 top-0 z-30" @click="close" v-if="!game">
            <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
          </button>

          <transition name="fade">
            <div class="absolute left-0 w-full h-full flex items-end justify-around" style="bottom: 2%"
                 v-if="!hideCabinets">
              <div class="px-2 relative w-full h-136"
                   :class="[cabinet.freeToPlay ? 'cursor-default' : 'cursor-not-allowed']"
                   v-for="(cabinet, index) in barCopy.cabinets">
                <transition name="fade">
                  <custom-image :original="cabinet.img"
                                class-list="absolute w-auto h-full top-0 left-0 right-0 mx-auto"/>
                </transition>
                <custom-image v-if="cabinet.reserved" :original="cabinet.reserved"
                              class-list="absolute w-auto h-full top-0 left-0 right-0 mx-auto"/>
                <transition name="fade">
                  <custom-image v-if="cabinet.playing" :original="cabinet.playing"
                                class-list="absolute w-auto h-full top-0 left-0 right-0 mx-auto"/>
                </transition>
                <custom-image v-if="cabinet.type === 'awp'" original="/img/glimpse/cabinets/glamour/glamour_bench.png"
                              class-list="absolute w-auto h-full top-0 left-0 right-0 mx-auto"/>
                <custom-image v-else original="/img/glimpse/cabinets/VLT/vlt_bench.png"
                              class-list="absolute w-auto h-full top-0 left-0 right-0 mx-auto"/>

                <div class="flex flex-col items-center justify-center gap-2 absolute"
                     v-if="cabinet.freeToPlay && !cabinet.reservingCabinet"
                     style="top: 60%; left: 50%; transform: translateX(-50%)">
                  <button @click="startPlaying(cabinet, index)"
                          class="px-4 py-1 text-xs font-semibold uppercase rounded-full cursor-pointer border-2 border-transparent bg-secondary text-gray-900 hover:bg-primary">
                    Gioca
                  </button>
                  <button @click="openCabinetReservationMenu(cabinet, index)"
                          class="px-4 py-1 text-xs font-semibold uppercase rounded-full cursor-pointer border-2 border-transparent bg-secondary text-gray-900 hover:bg-primary">
                    Riserva
                  </button>
                </div>
                <div class="flex flex-col items-center justify-center gap-2 absolute"
                     v-if="!cabinet.freeToPlay && cabinet.reserved"
                     style="top: 60%; left: 50%; transform: translateX(-50%)">
                  <button @click="removeCabinetReservation(cabinet, index)"
                          class="px-4 py-1 text-xs font-semibold uppercase rounded-full cursor-pointer border-2 border-transparent bg-secondary text-gray-900 hover:bg-primary">
                    Annula Riserva
                  </button>
                </div>
                <div class="flex flex-col items-center justify-center gap-4 absolute bg-gray-800 p-6 rounded-lg shadow-xl"
                     v-if="cabinet.freeToPlay && cabinet.reservingCabinet"
                     style="top: 40%; left: 50%; transform: translateX(-50%)">
                  <button class="self-end z-30" @click="closeCabinetReservationMenu(cabinet, index)">
                    <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width={2}
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  </button>
                  <div class="flex flex-col gap-1">
                    <label for="hours" class="uppercase text-sm text-white font-semibold">{{ hours }} {{ hours == 1 ? 'Ora' : 'Ore' }}</label>
                    <input type="range" id="hours" name="hours" min="1" max="8" step="1" v-model="hours"/>
                  </div>
                  <button @click="reserveCabinet(cabinet, index)"
                          class="px-8 py-1 text-xs font-semibold uppercase rounded-full cursor-pointer border-2 border-transparent bg-secondary text-gray-900 hover:bg-primary">
                    Riserva <br/> <span class="font-semibold text-gray-900">{{ reservingPrice | money }}</span>
                  </button>
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <PlayGame v-if="game" :game="game" :cabinet-type="barCopy.cabinets[selectedCabinetIndex].type"
                      @close="closeGame"/>
          </transition>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import PlayGame from "@/components/PlayGame";

export default {
  name: "ClientBarPopup",

  components: {PlayGame},

  props: {
    bar: {
      type: Object,
      required: true,
    },
    barIndex: {
      type: Number,
      required: true
    },
    region: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      showBar: false,
      selectedCabinetIndex: null,
      game: null,
      barCopy: null,
      hideCabinets: true,
      hours: 1
    }
  },

  computed: {
    barBg() {
      let wallpaper = this.bar.availableWallpapers.find(wallpaper => {
        return wallpaper.selected === true;
      })

      return wallpaper.bg
    },
    reservingPrice() {
      return this.hours * 5;
    }
  },

  mounted() {
    this.barCopy = this.bar
    this.showBar = true;

    setTimeout(() => {
      this.hideCabinets = false
    }, 1000);
  },

  methods: {
    startPlaying(cabinet, index) {
      if (!cabinet.freeToPlay) {
        return;
      }
      this.selectedCabinetIndex = index;

      if (cabinet.type === 'awp') {
        cabinet.playing = 'img/glimpse/cabinets/glamour/glamour_vulto2.png'
      } else {
        cabinet.playing = 'img/glimpse/cabinets/VLT/vlt_vulto1.png'
      }

      this.$forceUpdate();

      setTimeout(() => {
        this.game = this.$root.$data.games[cabinet.type][cabinet.game];
      }, 3000)
    },
    close() {
      this.hideCabinets = true;

      setTimeout(() => {
        this.showBar = false;
        this.game = null;
      }, 500);

      setTimeout(() => {
        this.$emit('closeBarPopup')
      }, 700);
    },
    openCabinetReservationMenu(cabinet, index) {
        cabinet.reservingCabinet = true;
        this.barCopy.cabinets[index] = cabinet;
        this.$forceUpdate();
    },
    closeCabinetReservationMenu(cabinet, index) {
        cabinet.reservingCabinet = false;
        this.barCopy.cabinets[index] = cabinet;
        this.$forceUpdate();
    },
    reserveCabinet(cabinet, index) {
      if (cabinet.type === 'vlt') {
        cabinet.reserved = '/img/glimpse/cabinets/VLT/vlt_riservato.png';
      } else {
        cabinet.reserved = '/img/glimpse/cabinets/glamour/glamour_reservato.png'
      }
      cabinet.freeToPlay = false;
      cabinet.playing = false;
      cabinet.reservingCabinet = false;

      this.barCopy.cabinets[index] = cabinet;
      this.$root.$data.barsPerRegion[this.region][this.barIndex] = this.barCopy;

      this.$forceUpdate();
    },
    removeCabinetReservation(cabinet, index) {
      delete cabinet.reserved;
      cabinet.freeToPlay = true;

      this.barCopy.cabinets[index] = cabinet;
      this.$root.$data.barsPerRegion[this.region][this.barIndex] = this.barCopy;

      this.$forceUpdate();
    },
    closeGame() {
      this.game = null;
      setTimeout(() => {
        delete this.barCopy.cabinets[this.selectedCabinetIndex].playing;

        this.$forceUpdate()
      }, 3000)
    }
  },
}
</script>

<style scoped lang="postcss">
.curtain-enter-active, .curtain-leave-active {
  transition: all 0.7s;
  transition-delay: 100ms;
}

.curtain-enter-active {
}

.curtain-enter, .curtain-leave-to {
  width: 0;
  height: 0 !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
